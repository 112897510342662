import React from 'react'
import { useSelector } from 'react-redux'
import { ThemeProvider } from 'styled-components'
import { IStore, useAppDispatch } from '@store/store'
import { Button, Modal, Tooltip } from '@mui/material'
import { countryToCurrency } from '@utils/common'
import { getCartTotalPrice, getCartTotalWeight, getCommonCartSale, getMaxPercentage } from '@components/views/teaPage/components/products/components/tableView/utils/common'
import { StyledCartData } from '@components/views/cartPage/components/cartData/styles/cartData.styled'
import { CheckoutProblem } from '@components/views/cartPage/components/cartData/components/checkoutProblem/checkoutProblem'
import { CheckoutDone } from '@components/views/cartPage/components/cartData/components/checkoutDone/checkoutDone'
import { Orders } from '@endpoints/endpoints/cart/type'
import { checkoutThunk, deleteAllCartItemThunk, setCheckoutDoneStatusThunk, setCheckoutProblemStatusThunk, setProblemOrderStatusThunk } from '@store/shoppingСart'
// @ts-ignore
import { ProblemOrderStatus } from '@types/cartTypes'

import { commonStyle } from '../../../../../styles'
import { VIP_SPB_SALE } from '@utils/consts'

export function CartData() {
  const dispatch = useAppDispatch()

  const colorTheme = useSelector((state: IStore) => state.theme.colorTheme)
  const cart = useSelector((state: IStore) => state.cart.items)
  const user = useSelector((state: IStore) => state.auth.user)
  const cartCondition = useSelector((state: IStore) => state.cart.condition)
  const productType = useSelector((state: IStore) => state.products.filters.productType)
  const checkoutDoneOpen = useSelector((state: IStore) => state.cart.isCheckoutDone)
  const checkoutProblemOpen = useSelector((state: IStore) => state.cart.isCheckoutProblem)


  const theme = {
    color: commonStyle[colorTheme].color,
    secondColor: commonStyle[colorTheme].secondColor,
    tertiaryColor: commonStyle[colorTheme].tertiaryColor,
  }

  const price = productType === 'SPB' ? getCartTotalPrice(cart, 'СПБ').toFixed(3) : getCartTotalPrice(cart).toFixed(3)
  const commonWeight = productType === 'SPB' ? getCartTotalWeight(cart, 'СПБ').toFixed(3) : getCartTotalWeight(cart).toFixed(3)

  let minOrder = 0

  // Проверяем, является ли пользователь VIP. Если пользователь не VIP, то настраиваем минимальный заказ.
  if (user?.status !== 'VIP') {
    // Если существует условие корзины (cartCondition), проверяем его
    if (cartCondition && cartCondition[productType]) {
      // Устанавливаем минимальный заказ, если есть данные для данного типа продукта
      minOrder = cartCondition[productType].minOrderMoney
    }
  }

  const commonSale = getCommonCartSale(
    cart,
    productType,
    user,
    cartCondition ? getMaxPercentage(cartCondition[productType].specialConditions, +price) : undefined,
  )

  const specialSalePercentage = productType && cartCondition && cartCondition[productType].specialConditions.length
    ? getMaxPercentage(cartCondition[productType].specialConditions, +price)
    : 0

  // Получаем цену за вычетом скидок за объём и за статус клиента
  const priceWithSale = +(+price - commonSale).toFixed(0)

  // Чтобы понять остаток скидки, которые дают скидочные позиции в СПБ вычитаю из начальной стоимости конечную и все скидки
  const discountItemsSales = (+price - priceWithSale - +price * VIP_SPB_SALE - +price * (specialSalePercentage / 100)).toFixed(0)


  const handleCheckout = async () => {
    const orderStatusProblem = await checkOrder()

    if (!orderStatusProblem.status) {
      const orders: Orders = []

      //Определяю товары для типа корзины
      const currentProductTypeItems = productType === 'SPB' ?
        Object.values(cart).filter(item => item.region === 'СПБ') : //Товары для СПБ
        Object.values(cart).filter(item => item.region !== 'СПБ') //Товары для Китая

      currentProductTypeItems.forEach(item => {
        if(item.item && item.number) {

          orders.push({
            art: item.item.art,
            type: item.unit ?? '',
            value: item.number
          })
        }
      })

      // Оформляем заказ
      await dispatch(checkoutThunk({ productType, orders: orders }))
      // Очистить корзину после заказа
      await dispatch(deleteAllCartItemThunk())
    }
  }

  const handleCheckoutProblemClose = () => {
    dispatch(setCheckoutProblemStatusThunk())
  }

  const handleCheckoutDoneClose = () => {
    dispatch(setCheckoutDoneStatusThunk())
  }

  const checkOrder = async (): Promise<ProblemOrderStatus> => {
    // Если стоимость заказа меньше минимальной стоимости заказа
    if (priceWithSale < minOrder) {
      return await dispatch(setProblemOrderStatusThunk({
        status: true,
        message: `Сумма заказа ${priceWithSale} ниже минимальной суммы заказа ${minOrder} ${countryToCurrency(productType)}`
      })).unwrap()
    }

    // Определяю товары для типа корзины (кроме коробок, для них шаг всегда 1)
    const currentProductTypeItems = productType === 'SPB' ?
      Object.values(cart).filter(item => item.region === 'СПБ') :
      Object.values(cart).filter(item => item.region !== 'СПБ')

    // Если количество товара не кратно шагу заказа товара
    for (const item of currentProductTypeItems) {
      if (
        item.number &&
        item.item && item.number % item.item.step !== 0 &&
        item.unit !== 'BOX'
      ) {
        return await dispatch(setProblemOrderStatusThunk({
          status: true,
          message: `Количество товара ${item.item.art} выбрано некорректно, т.к. не соответствует шагу заказа - ${item.item.step}`
        })).unwrap()
      }
    }

    return await dispatch(setProblemOrderStatusThunk({
      status: false,
      message: ''
    })).unwrap()
  }

  return (
    <ThemeProvider theme={theme}>
      <StyledCartData>
        <h2>Заказ</h2>

        <div className="info-container">
          <div className="info weight">
            <p className="first">Вес</p>
            <p className="second">{(+commonWeight).toFixed(2)} кг</p>
          </div>

          <div className="info sum-without-sales">
            <p className="first">Cумма без скидки</p>
            <p className="second">{(+price).toFixed(0)} {countryToCurrency(productType)}</p>
          </div>

          {/* Если есть скидочные позиции */}
          {productType === 'SPB' && +discountItemsSales > 0 && <div className="info specials-sales">
            <p className="first">Скидочные позиции</p>
            <p className="second">-{discountItemsSales} {countryToCurrency(productType)}</p>
          </div>}

          {/* Если заказ из SPB, то показываем скидку за объём товара */}
          {productType === 'SPB' && specialSalePercentage > 0 && <div className="info specials-sales">
            <Tooltip title="Cкидка за объём: от 30k - 5%, от 50k - 7%, от 80k - 8%" arrow>
              <p className="first">Cкидка за объём заказа</p>
            </Tooltip>
            <p className="second">-{(+price * (specialSalePercentage / 100)).toFixed(0)} {countryToCurrency(productType)}</p>
          </div>}

          {/* Если заказ из SPB и пользователь VIP, то показываем скидку клиента */}
          {user?.status === 'VIP' && <div className="info client-sales">
            <Tooltip title="ВИП-скидка 10%." arrow>
              <p className="first">Cкидка клиента</p>
            </Tooltip>
            <p className="second">-{(+price * VIP_SPB_SALE).toFixed(0)} {countryToCurrency(productType)}</p>
          </div>}

          <div className="info price">
            <p className="first">Итого</p>
            <p className="second">{priceWithSale.toFixed(0)} {countryToCurrency(productType)}</p>
          </div>
        </div>

        <div>
          {/* Если сумма заказа превышает минимальный заказ, то показывем кнопку оформить */}
          {(minOrder <= priceWithSale) &&
            <Button className="checkout" variant="contained" onClick={handleCheckout} disabled={!user}>
            Оформить
            </Button>}

          {/* Если сумма заказа меньше минимального, то показываем сумму, которую необходимо добрать до заказа */}
          {(minOrder > priceWithSale) &&
            <Button className="checkout" variant="contained" disabled={!user || (minOrder > priceWithSale)}>
              До заказа ещё {(minOrder - priceWithSale).toFixed(0)} {countryToCurrency(productType)}
            </Button>}
        </div>

        {/* Если сумма заказа меньше минимального заказ, то показывем минимальный заказ */}
        <div className="min-order">
          <p className="first">Минимальный заказ </p>
          <p className="second">{minOrder} {countryToCurrency(productType)}</p>
        </div>

        <Modal
          open={checkoutProblemOpen}
          onClose={handleCheckoutProblemClose}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
          className="checkout-problem-modal"
        >
          <div className="modal-content">
            <CheckoutProblem />
          </div>
        </Modal>

        <Modal
          open={checkoutDoneOpen}
          onClose={handleCheckoutDoneClose}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
          className="checkout-problem-modal"
        >
          <div className="modal-content">
            <CheckoutDone onClose={handleCheckoutDoneClose} />
          </div>
        </Modal>
      </StyledCartData>
    </ThemeProvider>
  )
}
