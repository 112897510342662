import React from 'react'
import { useSelector } from 'react-redux'
import { IStore, useAppDispatch } from '@store/store'
import { ThemeProvider } from 'styled-components'
import { initialFilters, updateProductFilterThunk } from '@store/products'
// @ts-ignore
import { Routers } from '@types/routers'
import { useNavigate } from 'react-router-dom'
import Icons from '@icons/icons'
import { StyledCartBackToCatalog } from '@components/views/cartPage/components/cartBackToCatalog/styles/cartBackToCatalog.styled'

import { commonStyle } from '../../../../../styles'

export function CartBackToCatalog() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const colorTheme = useSelector((state: IStore) => state.theme.colorTheme)

  const theme = {
    color: commonStyle[colorTheme].color,
    secondColor: commonStyle[colorTheme].secondColor
  }

  const setRouter = (routerName: Routers) => {
    navigate(routerName)
    dispatch(updateProductFilterThunk(initialFilters))
  }

  const goToCatalog = () => {
    setRouter('/')
  }

  return (
    <ThemeProvider theme={theme}>
      <StyledCartBackToCatalog>
        <div onClick={goToCatalog}>
          <Icons name='arrow-back' color='#828282' size='24' className='icon' />
          <p>Вернуться в каталог</p>
        </div>
      </StyledCartBackToCatalog>
    </ThemeProvider>
  )
}
