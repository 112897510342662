import styled from 'styled-components'

import { commonStyle } from '../../../../styles'

export const StyledCartPage = styled.div.attrs(() => ({
  className: 'cart-page page',
}))`
  position: relative;

  .cart-container {
    display: grid;
    position: relative;

    grid-template-areas:
    //"news news news"
    "back back back"
    "name filters filters"
    "products products data";

    grid-template-rows: 2.5rem 2.5rem 1fr;
    grid-template-columns: 10rem 1fr 16.5rem;
    gap: 1rem 2rem;
  }

  .empty-cart {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    p {
      font-size: ${() => commonStyle.fonts.fs20};
      font-weight: ${() => commonStyle.fontStyles.fw600};
      color: ${props => props.theme.secondColor};
    }
  }
`
